import React, { Component } from "react";

class ResultCard extends Component {
   
    render() {
        return (
       
            <div>
                <h2>Result Card</h2>
            </div>

        )
    }
}

export default ResultCard;

import React, { Component } from "react";

class ReportCard extends Component {
   
    render() {
        return (
       
            <div>
                <h1>Report Card</h1>
            </div>

        )
    }
}

export default ReportCard;
